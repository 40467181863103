import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | Iron & Grit Fitness Hakkında
			</title>
			<meta name={"description"} content={"Iron & Grit Fitness tek bir vizyonla kurulmuştur: fiziksel egzersizin ötesine geçerek güç, karakter ve kararlılık oluşturmaya odaklanan bir spor salonu ortamı yaratmak."} />
			<meta property={"og:title"} content={"Hakkımızda | Iron & Grit Fitness Hakkında"} />
			<meta property={"og:description"} content={"Iron & Grit Fitness tek bir vizyonla kurulmuştur: fiziksel egzersizin ötesine geçerek güç, karakter ve kararlılık oluşturmaya odaklanan bir spor salonu ortamı yaratmak."} />
			<meta property={"og:image"} content={"https://soviory.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://soviory.live/img/1800951.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://soviory.live/img/1800951.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://soviory.live/img/1800951.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://soviory.live/img/1800951.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://soviory.live/img/1800951.png"} />
			<meta name={"msapplication-TileImage"} content={"https://soviory.live/img/1800951.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Hakkımızda
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Iron & Grit Fitness tek bir vizyonla kurulmuştur: fiziksel egzersizin ötesine geçerek güç, karakter ve kararlılık oluşturmaya odaklanan bir spor salonu ortamı yaratmak. Etik anlayışımız, gerçek zindeliğin fiziksel cesaret kadar zihinsel cesaretle de ilgili olduğu fikri etrafında dönmektedir.
				</Text>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="40%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						1
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Bizim Felsefemiz
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Iron & Grit'te ilham veren bir atmosfer yaratmaya inanıyoruz. Bu sadece ağırlık kaldırmaktan daha fazlasıdır - azim ve esneklik zihniyetini geliştirmekle ilgilidir. Spor salonumuz, fitness konusunda ciddi olan ve yoğunlukları ve adanmışlıklarıyla rezonansa giren bir alan arayanlar için bir sığınak olacak şekilde tasarlanmıştır.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						2
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Kapsamlı Fitness Yaklaşımı
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Kuvvet antrenmanı, dayanıklılık geliştirme ve beslenme rehberliğini içeren bütünsel bir fitness yaklaşımı sunuyoruz.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						3
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Topluluk ve Kültür
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Iron & Grit sadece bir spor salonu değil, bir topluluktur. Destek, teşvik ve karşılıklı gelişim kültürünü teşvik ediyoruz.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://soviory.live/img/5.jpg"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://soviory.live/img/6.jpg"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://soviory.live/img/7.jpg"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://soviory.live/img/8.jpg"
							position="absolute"
							width="100%"
							left={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://soviory.live/img/9.jpg"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					Iron & Grit'i Diğerlerinden Ayıran Nedir?
					<br />
					<br />
					Özel Antrenman Ortamı - Spor salonumuz, özellikle yoğun antrenmanlara değer veren erkek kitleye hitap eden, odaklanmış ve motive edici bir ortam yaratmak için tasarlanmıştır.
					<br />
					<br />
					Uzmanlık ve Destek - Ekibimiz sadece fitness konusunda uzman değil, aynı zamanda üyelerin en iyi hallerine ulaşmalarına yardımcı olma konusunda tutkulu olan deneyimli eğitmenlerden oluşmaktadır.
				</Text>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});